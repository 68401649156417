import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;

export default ({
  cards = [
    {
      imageSrc: ShieldIconImage,
      title: "Secure",
      description: "We strictly only deal with vendors that provide top notch security.",
      url: "https://timerse.com"
    },
    {
      imageSrc: SupportIconImage,
      title: "24/7 Support",
      description: "Lorem ipsum donor amet siti ceali placeholder text",
      url: "https://google.com"
    },
    {
      imageSrc: CustomizeIconImage,
      title: "Customizable",
      description: "Lorem ipsum donor amet siti ceali placeholder text",
      url: "https://reddit.com"
    }
  ],
  linkText = "Learn More",
  heading = "",
  subheading = "",
  description = "",
  imageContainerCss = null,
  imageCss = null
}) => {
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  return (
    <Container>
      <ContentWithPaddingXl>
        {false && <Subheading>{subheading}</Subheading>}
        {false && <Heading>{heading}</Heading>}
      
        In today's digital age, children are growing up surrounded by the internet and all its wonders. The online world can be a fantastic resource for education, entertainment, and communication, but it also poses potential risks for our children. As responsible adults, it's crucial to take measures to ensure online safety for our kids. This article provides a comprehensive guide to help parents, caregivers, and educators protect children while they navigate the vast online landscape.

      <br></br> <br></br>

        1. **Open Communication**: The foundation of online safety is open and honest communication. 
        <br></br> <br></br>
        Talk to your children about the internet, its benefits, and potential dangers. Encourage them to come to you with any concerns or questions.
        <br></br> <br></br>
2. **Educate About Online Dangers**: Make sure your children are aware of the dangers they might encounter online, such as cyberbullying, inappropriate content, and online predators. Discuss these topics openly, and explain how to recognize and respond to threats.
<br></br> <br></br>
3. **Set Age-Appropriate Boundaries**: Different age groups require different levels of internet freedom. Establish clear rules and boundaries for internet usage that align with your child's age, maturity, and online experience.
<br></br> <br></br>
4. **Use Parental Control Software**: Numerous parental control tools are available to help you monitor and manage your child's online activities. These tools can filter websites, set time limits, and provide detailed reports on your child's online behavior.
<br></br> <br></br>
5. **Teach Online Etiquette**: Encourage good online behavior by teaching your children about internet etiquette. Discuss the importance of respecting others' privacy and being kind and respectful in online interactions.
<br></br> <br></br>
6. **Secure Personal Information**: Emphasize the importance of keeping personal information, such as full name, address, school name, and contact details, private. Explain that they should only share this information with trusted individuals.
<br></br> <br></br>
7. **Regularly Review Friend Lists**: If your child is on social media or online gaming platforms, review their friend lists and followers. Ensure they only connect with people they know in real life.
<br></br> <br></br>
8. **Keep Devices in Common Areas**: Encourage children to use the internet in common areas of the house, where you can easily monitor their activities. This helps prevent them from engaging in risky behavior in secret.
<br></br> <br></br>
9. **Teach Critical Thinking**: Help your children develop critical thinking skills. Teach them to question the credibility of information they find online and to verify the sources before accepting anything as fact.
<br></br> <br></br>
10. **Know the Apps and Games**: Be aware of the apps, games, and websites your child uses. Research them to ensure they are appropriate for your child's age and interests.
<br></br> <br></br>
11. **Address Cyberbullying**: If your child experiences cyberbullying, take it seriously. Offer support, document the evidence, and report the incident to the appropriate authorities or website administrators.
<br></br> <br></br>
12. **Lead by Example**: Children often learn by example. Set a positive example of responsible online behavior by demonstrating respect, kindness, and healthy boundaries in your own online interactions.
<br></br> <br></br>
13. **Stay Informed**: Stay updated on the latest online trends and potential risks. Online platforms and technologies are constantly evolving, so make an effort to remain informed about what your child might encounter.
<br></br> <br></br>
14. **Report Suspicious Activity**: Encourage your children to report any suspicious or uncomfortable encounters they have online. This is an important step in preventing potential dangers.
<br></br> <br></br>
15. **Balance Screen Time**: Promote a balanced lifestyle by setting limits on screen time. Encourage physical activity, socializing with friends offline, and engaging in non-screen activities.
<br></br> <br></br>


<Subheading>
In conclusion, the internet is an incredible resource for children, but it's essential to ensure their safety while exploring the digital world. By maintaining open communication, educating children about online dangers, and setting clear boundaries, you can help your child navigate the internet safely and responsibly. Stay involved in your child's online activities, and be their trusted guide in the ever-expanding online universe.


</Subheading>

        <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card href={card.url}>
                <span className="imageContainer" css={imageContainerCss}>
                  <img src={card.imageSrc} alt="" css={imageCss} />
                </span>
                <span className="title">{card.title}</span>
                <p className="description">{card.description}</p>
                {linkText && (
                  <span className="link">
                    <span>{linkText}</span>
                    <ArrowRightIcon className="icon" />
                  </span>
                )}
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob />
    </Container>
  );
};
