import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import HeaderBase, { NavLinks, NavLink, PrimaryLink } from "components/headers/light.js";

const Subheading = tw.span`uppercase tracking-wider text-sm`;


const buttonRounded = true;


const buttonRoundedCss = buttonRounded && tw`rounded-full`;
const navLinks = [
  <NavLinks key={1}>
     <NavLink href="#">Home</NavLink>
    <NavLink href="#aboutus">About</NavLink>
    <NavLink href="/#FAQs">FAQs</NavLink>
    <NavLink href="/#Pricing">Pricing</NavLink>
    <NavLink href="/#Blog">Blog</NavLink>
    <NavLink href="/#contactus">Contact Us</NavLink>
  </NavLinks>,
  <NavLinks key={2}>
    <NavLink href="/LoginPage" tw="lg:ml-12!">
      Login
    </NavLink>
    <PrimaryLink css={buttonRoundedCss} href="/SignupPage">
      Sign
    </PrimaryLink>
  </NavLinks>]

export default () => {
  return (
    <AnimationRevealPage>
      <Header links={navLinks} /> 
      <MainFeature1
      description="The online world can be a fantastic resource for education, entertainment, and communication, but it also poses potential risks for our children"
        subheading={<Subheading></Subheading>}
        heading="Ensuring Online Safety for Children."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
    
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="The online world can be a fantastic resource for education, entertainment, and communication, but it also poses potential risks for our children."
        cards={[ ]}
        linkText=""
      />
     
      <Footer />
    </AnimationRevealPage>
  );
};
