import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";


import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Blog from "components/blogs/GridWithFeaturedPost.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import Testimonial from "components/testimonials/TwoColumnWithImage.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStartedLight.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

import AboutUS from "components/features/AboutUs.js";

import GetStartedKids from "components/forms/GetStarted.js";

import ContactUsPage from "components/forms/TwoColContactUsWithIllustrationFullForm.js";


import Blogs from "pages/BlogsList.js"

const HighlightedText = tw.span`text-primary-500`

export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
     
      <FeatureStats/>
      <MainFeature
        heading={<>We create events that simulate potential risks for children.<br></br><br></br> This equips them with skills to identify dangers.</>}
      />
      <Features 
        heading={<>Amazing <HighlightedText>Features</HighlightedText></>}
      />
     
  
    <Pricing/>
  {true &&   <Blogs/>}
    <AboutUS></AboutUS>
    <ContactUsPage/>
      <FAQ
        heading={<>Any <HighlightedText>Questions ?</HighlightedText></>}
      />
   
     {false && <GetStarted/> }
      <Footer />
    </AnimationRevealPage>
  );
}
