import React, { useState } from 'react';
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import HeaderBase, { NavLinks, NavLink, PrimaryLink } from "components/headers/light.js";
//import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithVerticalPadding } from "components/misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as QuotesLeftIconBase } from "images/quotes-l.svg"
import { ReactComponent as SvgDecoratorBlob1 } from "images/dot-pattern.svg"
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";



// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getFirestore, collection, addDoc,getDoc,doc, DocumentSnapshot,getDocs} from "firebase/firestore";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDv7kC1qbHXr1Y3UQ-gKSBJn4TQ83cKHzY",
  authDomain: "parents-help-303df.firebaseapp.com",
  databaseURL: "https://parents-help-303df-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "parents-help-303df",
  storageBucket: "parents-help-303df.appspot.com",
  messagingSenderId: "427190356387",
  appId: "1:427190356387:web:d3435ebdf355f887cc3216",
  measurementId: "G-NRVNEZW8F4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);



const Header = tw(HeaderBase)`max-w-none`;
const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(Column)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;
const Heading = tw(SectionHeading)`text-left text-primary-900 leading-snug xl:text-6xl`;
const Description = tw(SectionDescription)`text-left mt-4 lg:text-base text-gray-700 max-w-lg`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 inline-block w-56 tracking-wide text-center py-5`;
const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const ImageColumn = tw(Column)`ml-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-32`;
const ImageContainer = tw.div`relative z-40 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image = tw.img`max-w-full w-96 rounded-t sm:rounded relative z-20`;
const Offsetbackground = tw.div`absolute inset-0 bg-gray-300 rounded xl:-mb-8`
const ImageDecoratorBlob = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none z-10 absolute right-0 bottom-0 transform translate-x-10 translate-y-10 h-32 w-32 opacity-25 text-gray-900 fill-current`}
`;
const Testimonial = tw.div`max-w-sm rounded-b md:rounded-none relative sm:absolute bottom-0 inset-x-0 z-20 px-8 py-6 sm:px-10 sm:py-8 bg-primary-900 text-gray-400 font-medium transform md:-translate-x-32 text-sm leading-relaxed md:-mr-16 xl:mr-0`
const QuotesLeftIcon = tw(QuotesLeftIconBase)`w-16 h-16 md:w-12 md:h-12 absolute top-0 left-0 text-gray-100 md:text-red-500 transform translate-x-1 md:-translate-x-1/2 md:-translate-y-5 opacity-10 md:opacity-100`
const Quote = tw.blockquote``
const CustomerName = tw.p`mt-4 font-bold`
const CustomerCompany = tw.p`mt-1 text-sm text-gray-500`


const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col lg:flex-row`
const Input = tw.input`border-2 px-5 py-3 rounded focus:outline-none font-medium transition duration-300 hocus:border-primary-500`

const SubmitButton = tw(PrimaryButtonBase)`inline-block lg:ml-6 mt-6 lg:mt-0`



const db = getFirestore(app);
const dbRef = collection(db, "KIDZZ_CONTACTUS_FORM");




export default ({
  
  subheading = "Contact Us",
 // heading = <><span >We keep children </span><wbr/> <span tw="text-primary-500">safe</span>.</>,
// heading = <><span >Make sure children are </span><wbr/> <span tw="text-primary-500">protected online</span>.</>,
   
  
heading = <><span >Empowering children with the ability to recognize online </span><wbr/> <span tw="text-primary-500">risks</span>.</>,
  submitButtonText = "Contact Me",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
  description = "Empowering children with the ability to recognize online risks",
//  description = "Empowering children with the ability to recognize online risks and safe environment to practice potential threats.",
  imageSrc = "https://static.wixstatic.com/media/99d9bf_37fbc20d81fe4cb7956e060c93b65efe~mv2.jpg/v1/fill/w_613,h_409,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/99d9bf_37fbc20d81fe4cb7956e060c93b65efe~mv2.jpg",
  imageDecoratorBlob = true,
  primaryButtonUrl = "https://google.com",
  primaryButtonText = "Tell me more",
  buttonRounded = true,
  features = [], // ["Easy to integrate & use", "Reduce lots of time waste of employees", "Understand patterns of effective schedule meetings"],
  testimonial = {
    quote: "Increase meeting efficiency easily with smart tool.",
    customerName: "Liran cohen",
    customerCompany: "KenLo Inc."
  }
  
}) => {


  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };


  function logInfo()
  {
  

    if(!validateEmail(email))
    {
    alert("Please enter a valid email");
      return;
    }

    const now = new Date(); 
    
  var data = {
    dateEntered:now,
    email:email,
    TELL_ME_MORE:"YES"
   
  };
  
  
  
  AddUser(data);
  
  
  console.log( email );
  
  
  setEmail("");
  
  
  
  //getusers();
  
  }
  
  
  async function AddUser(UserInfo) {    
  
  
    var data = UserInfo;
     addDoc(dbRef, data)
     .then(docRef => {
         console.log("Document has been added successfully");
     })
     .catch(error => {
         console.log(error);
     })
   
          
           
   }
   

  const [email, setEmail] = useState('');
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;
  const navLinks = [
    <NavLinks key={1}>
       <NavLink href="#">Home</NavLink>
      <NavLink href="#aboutus">About</NavLink>
      <NavLink href="/#FAQs">FAQs</NavLink>
      <NavLink href="/#Pricing">Pricing</NavLink>
      <NavLink href="/#Blog">Blog</NavLink>
      <NavLink href="/#contactus">Contact Us</NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <NavLink href="/LoginPage" tw="lg:ml-12!">
        Login
      </NavLink>
      <PrimaryLink css={buttonRoundedCss} href="/SignupPage">
        Sign Up
      </PrimaryLink>
    </NavLinks>
  ];
  return (
    <>
      <Header links={navLinks} />
      <Container>
        <ContentWithVerticalPadding>
          <Row>

          <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>{heading}</Heading>
           {false &&  <Description><b>{description}</b></Description>}
            <Form action={formAction} method={formMethod}>
              <Input type="email" name="email" placeholder="Your Email Address"   value={email}   onChange={(e) => setEmail(e.target.value)}/>
              <SubmitButton type="button"  onClick={logInfo}>Register</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>

        
          
            <ImageColumn>
              <ImageContainer>
                <Image src={imageSrc} />
                {imageDecoratorBlob && <ImageDecoratorBlob />}
               {false &&  <Testimonial>
                  <QuotesLeftIcon/>
                  <Quote>{testimonial.quote}</Quote>
                  <CustomerName>{testimonial.customerName}</CustomerName>
                  <CustomerCompany>{testimonial.customerCompany}</CustomerCompany>
                </Testimonial>}
              </ImageContainer>
              <Offsetbackground />
            </ImageColumn>
          </Row>
        </ContentWithVerticalPadding>
      </Container>
    </>
  );
};
