import React, { useState } from 'react';
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getFirestore, collection, addDoc,getDoc,doc, DocumentSnapshot,getDocs} from "firebase/firestore";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDv7kC1qbHXr1Y3UQ-gKSBJn4TQ83cKHzY",
  authDomain: "parents-help-303df.firebaseapp.com",
  databaseURL: "https://parents-help-303df-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "parents-help-303df",
  storageBucket: "parents-help-303df.appspot.com",
  messagingSenderId: "427190356387",
  appId: "1:427190356387:web:d3435ebdf355f887cc3216",
  measurementId: "G-NRVNEZW8F4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


const db = getFirestore(app);
const dbRef = collection(db, "KIDZZ_CONTACTUS_FORM");

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "Contact Us",
  heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr/> with us.</>,
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  function logInfo()
  {
  
    const now = new Date(); 
    
if(!validateEmail(email))
{
alert("Please enter a valid email");
  return;
}

  var data = {
    dateEntered:now,
    email:email,
    name:name,
    message:msgtext,
    CONTACT_US:"YES"
   
  };
  
  
  
  AddUser(data);
  
  
  console.log( email );
  
  
  setEmail("");
  setname("");
  setmsgtext("");
  
  
  
  //getusers();
  
  }
  
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  async function AddUser(UserInfo) {    
  
  
    var data = UserInfo;
     addDoc(dbRef, data)
     .then(docRef => {
         console.log("Document has been added successfully");
     })
     .catch(error => {
         console.log(error);
     })
   
          
           
   }
   

  const [email, setEmail] = useState('');
  const [msgtext, setmsgtext] = useState('');
  const [name, setname] = useState('');
  return (
    <Container id="contactus"  name="contactus">
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {false && <Description>{description}</Description>}
            <Form action={formAction} method={formMethod}>
            <Input type="email" name="email" placeholder="Your Email Address"   value={email}   onChange={(e) => setEmail(e.target.value)}/>
              <Input type="text" name="name" placeholder="Full Name"  value={name}   onChange={(e) => setname(e.target.value)} />
             

              <Textarea name="message" placeholder="Your Message Here"  value={msgtext}   onChange={(e) => setmsgtext(e.target.value)}/>
              
           
              <SubmitButton type="button"  onClick={logInfo}>Send</SubmitButton>



            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
