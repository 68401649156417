import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;

export default ({
  cards = [
    {
      imageSrc: ShieldIconImage,
      title: "Secure",
      description: "We strictly only deal with vendors that provide top notch security.",
      url: "https://timerse.com"
    },
    {
      imageSrc: SupportIconImage,
      title: "24/7 Support",
      description: "Lorem ipsum donor amet siti ceali placeholder text",
      url: "https://google.com"
    },
    {
      imageSrc: CustomizeIconImage,
      title: "Customizable",
      description: "Lorem ipsum donor amet siti ceali placeholder text",
      url: "https://reddit.com"
    }
  ],
  linkText = "Learn More",
  heading = "",
  subheading = "",
  description = "",
  imageContainerCss = null,
  imageCss = null
}) => {
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  return (
    <Container>
      <ContentWithPaddingXl>
        {false && <Subheading>{subheading}</Subheading>}
        {false && <Heading>{heading}</Heading>}
      
       
        Here are some of the most significant internet risks for children:
        <br></br> <br></br>
1. **Inappropriate Content:** The internet is filled with a vast amount of content, not all of which is suitable for children. Inappropriate content can include violent images, explicit material, and hate speech. Children may stumble upon such content accidentally while browsing or searching for information.
<br></br> <br></br>
2. **Cyberbullying:** Online platforms provide a space for cyberbullying, where children can become victims of hurtful, offensive, or threatening messages. This form of bullying can lead to severe emotional distress and has even driven some youngsters to self-harm or worse.
<br></br> <br></br>
3. **Privacy Concerns:** Children might unknowingly share personal information, such as their full names, addresses, or school details. This information can be exploited by malicious individuals or organizations for identity theft, scams, or harassment.
<br></br> <br></br>
4. **Online Predators:** There are individuals online who pretend to be someone they're not to manipulate, deceive, or harm children. These predators may attempt to befriend youngsters on social media or in chat rooms, eventually luring them into dangerous situations.
<br></br> <br></br>
5. **Addiction:** Excessive screen time and internet use can lead to addiction, where children become dependent on their devices and neglect other essential aspects of their lives, such as physical activity, academic responsibilities, and social interactions.
<br></br> <br></br>
6. **Phishing Scams:** Children may not be well-equipped to recognize phishing scams and might unwittingly click on malicious links or download harmful files. This can result in security breaches or the installation of harmful software on their devices.
<br></br> <br></br>
7. **Social Media Pressure:** Social media platforms often create a sense of competition and comparison among children, leading to low self-esteem and body image issues. The pressure to conform to certain standards or lifestyles can be emotionally taxing.
<br></br> <br></br>
To protect children from these internet risks, it is vital for parents and guardians to take an active role in their online activities. Here are some recommended steps:
<br></br> <br></br>
- **Open Communication:** Encourage open and honest discussions about the internet. Make sure children feel comfortable discussing any issues or concerns they may encounter online.
<br></br> <br></br>
- **Set Rules and Boundaries:** Establish clear guidelines on screen time, appropriate websites and apps, and online behavior. Enforce these rules consistently.
<br></br> <br></br>
- **Educate Them:** Teach children about the risks and how to recognize and avoid them. Make them aware of the importance of privacy and safe online practices.
<br></br> <br></br>
- **Use Parental Control Software:** Employ parental control software and tools to monitor and restrict access to certain content and websites. These can help safeguard your child's online experience.
<br></br> <br></br>
- **Stay Informed:** Keep yourself updated on the latest trends and threats in the online world. The internet is continuously evolving, and your knowledge should evolve with it.
<br></br> <br></br> <br></br> <br></br>



       <br></br> <br></br>


<Subheading>
  In conclusion, the internet offers incredible opportunities for children, but it also poses real risks. By educating themselves about these risks and taking proactive steps to protect their children online, parents can ensure a safer and more enriching online experience for their young ones.


</Subheading>

        <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card href={card.url}>
                <span className="imageContainer" css={imageContainerCss}>
                  <img src={card.imageSrc} alt="" css={imageCss} />
                </span>
                <span className="title">{card.title}</span>
                <p className="description">{card.description}</p>
                {linkText && (
                  <span className="link">
                    <span>{linkText}</span>
                    <ArrowRightIcon className="icon" />
                  </span>
                )}
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob />
    </Container>
  );
};
