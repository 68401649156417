import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full text-left`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = "Amazing Features", subheading = "Features", description = "Share bussines information in a simple way thorgh tech process." }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: ShieldIconImage,
      title: "Interactive Learning",
      description: "Reaching out to children through digital channels."
    },

    { imageSrc: FastIconImage, title: "Parental Involvement",
    description: "Updating parents in the process." },
    { imageSrc: SimpleIconImage, title: "Expert Support",
    description: "Providing assistance from skilled professionals." }
  ];

  if (!cards) cards = defaultCards;
  return (
    <Container id="aboutus" name="aboutus">
      <ThreeColumnContainer>
       
        <Heading>Our story</Heading>
        {true && <div>
          
          our story is one of love, concern, and a commitment to keeping our kids safe in this digital age.
<br></br><br></br>
As moms and dads, we understand the joys and challenges of parenting in the 21st century.
<br></br>
We've witnessed the incredible opportunities the internet brings, from educational resources that can ignite a child's passion for learning to the ability to stay connected with friends and family around the globe.
<br></br>
<br></br>
 At the same time, we've also experienced the worries and sleepless nights that come with knowing our children have access to content that might not always be suitable for their young minds.
 <br></br>
 <br></br>

Our journey began when we realized that the internet had become an integral part of our children's lives, and we felt the need to find a balance. We started researching, learning, and talking to other parents facing the same concerns. 
<br></br><br></br>
In this process, we discovered that while technology can be an amazing tool, there is no one-size-fits-all approach to keeping our children safe online.
<br></br>
<br></br>

We're here to provide resources, tips, and guidance to help you navigate the ever-changing landscape of online safety, from setting up parental controls to teaching your children about responsible internet use.

Together, we can create a safe digital environment for our children, where they can thrive, learn, and explore, all while knowing that we, as concerned parents, have their backs.
<br></br><br></br><br></br>
<b> 
 Join us on this journey to make the internet a better place for our kids and yours.
 </b>
          
          </div>}
        <VerticalSpacer />
      
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
